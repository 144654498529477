<template>
  <el-row class="total" :gutter="20">
    <el-col :span="3">
      <div class="item">
        <div class="title">进单数：</div>
        <div class="message">{{ in_order_count }}</div>
      </div>
    </el-col>
    <el-col :span="3">
      <div class="item">
        <div class="title">进单营业额：</div>
        <div class="message">{{ in_order_balance }}</div>
      </div>
    </el-col>
    <el-col :span="3">
      <div class="item">
        <div class="title">进单成本：</div>
        <div class="message">{{ in_order_cost }}</div>
      </div>
    </el-col>
    <el-col :span="3">
      <div class="item">
        <div class="title">出单数：</div>
        <div class="message">{{ out_order_count }}</div>
      </div>
    </el-col>
    <el-col :span="3">
      <div class="item">
        <div class="title">出单营业额：</div>
        <div class="message">{{ out_order_balance }}</div>
      </div>
    </el-col>
    <el-col :span="3">
      <div class="item">
        <div class="title">出单成本：</div>
        <div class="message">{{ out_order_cost }}</div>
      </div>
    </el-col>
    <el-col :span="3">
      <div class="item">
        <div class="title">剩余订单数：</div>
        <div class="message">{{ left_order_count }}</div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Total",
  props: {
    in_order_count: Number,
    in_order_balance: Number,
    in_order_cost: Number,
    out_order_count: Number,
    out_order_balance: Number,
    out_order_cost: Number,
    left_order_count: Number,
  },
};
</script>
<style lang="less" scoped>
.total {
  .item {
    margin-bottom: 20px;
    // display: flex;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10 #ccc;
    box-sizing: border-box;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #f6f6f6;
      padding: 10px;
      box-sizing: border-box;
    }
    .message {
      padding: 10px;
      box-sizing: border-box;
      color: red;
      font-size: 20px;
    }
  }
}
</style>