<template>
  <div class="total-statist">
    <total-nav @change="getInOutOrderStatistic"></total-nav>
    <total
      :in_order_count="in_order_count"
      :in_order_balance="in_order_balance"
      :in_order_cost="in_order_cost"
      :out_order_count="out_order_count"
      :out_order_balance="out_order_balance"
      :out_order_cost="out_order_cost"
      :left_order_count="left_order_count"
    ></total>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="产品统计" name="first">
        <prod-list :list="prodList"></prod-list>
      </el-tab-pane>
      <el-tab-pane label="快递统计" name="second">
        <express-list :list="expressList"></express-list
      ></el-tab-pane>
      <el-tab-pane label="进单统计" name="third">
        <order-list :list="getList"></order-list
      ></el-tab-pane>
      <el-tab-pane label="出单统计" name="fourth">
        <order-list :list="postList"></order-list
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import TotalNav from "./components/Nav";
import Total from "./components/Total";
import ProdList from "./components/ProdList";
import ExpressList from "./components/ExpressList";
import OrderList from "./components/OrderList";
export default {
  name: "TotalStatist",
  components: {
    TotalNav,
    Total,
    ProdList,
    ExpressList,
    OrderList,
  },
  data() {
    return {
      activeName: "first",
      prodList: [],
      expressList: [],
      getList: [],
      postList: [],
      in_order_count: null,
      in_order_balance: null,
      in_order_cost: null,
      out_order_count: null,
      out_order_balance: null,
      out_order_cost: null,
      left_order_count: null,
    };
  },
  methods: {
    //获取数据
    async getInOutOrderStatistic(data) {
      const res = await this.$store.dispatch("getInOutOrderStatistic", data);

      this.in_order_count = res.in_order_count;
      this.in_order_balance = res.in_order_balance;
      this.in_order_cost = res.in_order_cost;
      this.out_order_count = res.out_order_count;
      this.out_order_balance = res.out_order_balance;
      this.out_order_cost = res.out_order_cost;
      this.left_order_count = res.left_order_count;
      const {
        goods_statistic,
        express_statistic,
        in_statistic,
        out_statistic,
      } = res.pages;
      this.prodList = Object.values(goods_statistic);
      this.expressList = Object.values(express_statistic);
      this.getList = in_statistic;
      this.postList = out_statistic;
    },
    handleClick() {},
  },
};
</script>
<style lang="less" scoped>
.total-statist {
  /deep/.el-tabs__header {
    margin-bottom: 0;
  }
  /deep/.el-tabs__item.is-active {
    background-color: #fff;
  }
}
</style>