<template>
  <el-row :gutter="20" style="margin-bottom: 20px; flex: 1">
    <el-col :span="7">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </el-col>
  </el-row>
</template>
<script>
import { dateShortcuts, toDaytime } from "@/util/shortcuts";
export default {
  name: "Nav",
  data() {
    return {
      date: toDaytime,
      startTime: "",
      endTime: "",
      pickerOptions: {
        shortcuts: dateShortcuts,
      },
    };
  },
  watch: {
    date(e) {
      if (e) {
        this.startTime = Math.floor(e[0] / 1000);
        this.endTime = Math.floor(e[1] / 1000);
        this.handleChange();
      }
    },
  },
  created() {
    this.handleChange();
  },
  methods: {
    //选择时间
    handleChange() {
      this.$emit("change", {
        startTime: this.startTime,
        endTime: this.endTime,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  height: 34px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10 #ccc;
  box-sizing: border-box;
  .title {
    // width: 70px;
    padding: 0 10px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .message {
    padding: 0 10px;
    box-sizing: border-box;
    color: red;
    font-size: 20px;
  }
}
</style>