<template>
  <table-container :list="list">
    <el-table-column type="index" width="50" label="序号" align="center">
    </el-table-column>
    <el-table-column prop="name" label="产品" align="center"> </el-table-column>
    <el-table-column prop="count" label="数量" align="center"></el-table-column>
    <el-table-column
      prop="balance"
      label="总营业额"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="cost"
      label="总成本"
      align="center"
    ></el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "ProdList",
  props: {
    list: Array,
  },
};
</script>
<style lang="less" scoped>
</style>