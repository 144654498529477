<template>
  <table-container :list="list">
    <el-table-column type="index" width="50" label="序号" align="center">
    </el-table-column>
    <el-table-column prop="ordersn" label="订单号" align="center">
    </el-table-column>
    <el-table-column prop="price" label="金额" align="center"></el-table-column>
    <el-table-column
      prop="order_cost"
      label="成本"
      align="center"
    ></el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "OrderList",
  props: {
    list: Array,
  },
};
</script>
<style lang="less" scoped>
</style>